import { Game } from "../../shared/data-access/store/game/types/game-types";
import {
  SocketEvent,
  SocketResponse,
} from "../../shared/data-access/store/game/types/requests";

type Response = SocketResponse<
  "update_round_answer",
  SocketEvent<"update_round_answer">
>;

export const assertCorrectAnswer = (
  results?: Response,
  game?: Game
): Response["data"]["round"] | false => {
  if (!results || !game || results.data.id !== game.id) {
    return false;
  }
  return results.data.round;
};
