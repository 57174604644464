import { createSelector, createSlice } from "@reduxjs/toolkit";
import { allInUnion } from "../../../../utils/typescript/arrays/all-in-union";
import type { RootState } from "../store";
import type { Game } from "./types/game-types";
import type {
  SocketEventNames,
  SocketResponse,
  SocketResponseError,
  SocketResponses,
} from "./types/requests";

type AllowedMergeKeys = Exclude<keyof Game, "id" | "genie">;

const commonKeysChecker = allInUnion<AllowedMergeKeys>();

const COMMON_KEYS = commonKeysChecker([
  "finished",
  "skills",
  "ladder_points",
  "total_rounds",
  "current_round_index",
]);

type GameState = {
  loaded: boolean;
  game?: Game;
} & {
  [Key in Exclude<SocketEventNames, "init_game">]?: SocketResponse<Key>;
} & {
  errors: {
    [Key in SocketEventNames]?: SocketResponseError;
  };
  opponentLost: boolean;
};

const initialState: GameState = {
  loaded: false,
  errors: {},
  opponentLost: false,
};

const gameSlice = createSlice({
  name: "game",
  initialState: {
    ...initialState,
  },
  reducers: {
    updateGame(state: GameState, { payload }: { payload: Game }) {
      return {
        ...state,
        loaded: true,
        game: payload,
        errors: {
          ...state.errors,
          init_game: undefined,
        },
      };
    },
    updateEventResponse(
      state: GameState,
      { payload }: { payload: SocketResponses }
    ) {
      const update = {
        ...state,
      } as GameState;
      if (
        payload.data &&
        Object.keys(payload.data).some((key) =>
          COMMON_KEYS.includes(key as AllowedMergeKeys)
        )
      ) {
        update.game = {
          ...COMMON_KEYS.reduce(
            (game, key) => {
              if (key in payload.data) {
                return {
                  ...game,
                  [key]: (payload.data as Partial<Game>)[key],
                };
              }
              return game;
            },
            { ...(state.game || null) }
          ),
        } as Game;
      }
      return {
        ...update,
        [payload.call]: payload,
        errors: {
          [payload.call]: undefined,
        },
      };
    },
    reset() {
      return {
        ...initialState,
      };
    },
    resetEvent(state: GameState, { payload }: { payload: SocketEventNames }) {
      return {
        ...state,
        [payload]: undefined,
        errors: {
          ...state.errors,
          [payload]: undefined,
        },
      };
    },
    updateError(
      state: GameState,
      { payload }: { payload: SocketResponseError }
    ) {
      return {
        ...state,
        errors: {
          ...state.errors,
          [payload.call]: payload,
        },
      };
    },
    updateOpponentLost(state, { payload }: { payload: boolean }) {
      state.opponentLost = payload;
    },
  },
});

const { reducer, actions } = gameSlice;

export const {
  updateGame,
  updateEventResponse,
  updateError,
  resetEvent,
  reset,
  updateOpponentLost,
} = actions;

export const selectGameState = (state: RootState): GameState => state.game;

export const selectGame = createSelector(
  selectGameState,
  (state: GameState) => state.game
);

export const selectGameLoaded = createSelector(
  selectGameState,
  (state: GameState) => state.loaded
);

export const selectEventResponse = <
  T extends Exclude<SocketEventNames, "init_game">
>(
  type: T
) => {
  return createSelector(selectGameState, (state: GameState) => state[type]);
};

export const selectGameAndEventError = (type: SocketEventNames) => {
  return createSelector(
    selectGameState,
    (state: GameState) => state.errors[type] ?? state.errors["error_code"]
  );
};

export const selectOpponentLost = (state: RootState) => state.game.opponentLost;

export default reducer;
